import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const Logo = () => {
  return (
    <>
      <StaticImage
        src="../images/icon.png"
        alt="Sumadhura folium"
        placeholder="blurred"
        layout="fixed"
        width={75}
        height={65}
      />
    </>
  );
};

export default Logo;
