import React from "react";
import { css } from "@emotion/react";
import Container from "./Container";
import TitleComponent from "./TitleComponent";
import { FaStar } from "react-icons/fa";

const LocationComponet = () => {
  return (
    <>
      <Container>
        <TitleComponent title="Location" size="3vw" color="#7a8c57" />
        <span css={first}>
          {" "}
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.0969882068043!2d77.74099351482197!3d12.96564529085983!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae136cd72555af%3A0x26913f8aa6000aa!2sFolium%20by%20Sumadhura!5e0!3m2!1sen!2sin!4v1634214732434!5m2!1sen!2sin"
            style={{
              width: "100%",
              height: "488px",
              border: "none",
            }}
            allowfullscreen=""
            loading="lazy"
          />
          <div css={second}>
            <div>
              <TitleComponent
                title="Work Places"
                size="1.5vw"
                color="#000"
                case="uppercase"
              />

              <ul>
                <li>
                  <FaStar /> Manyata Tech Park
                </li>
                <li>
                  <FaStar /> International Tech Park Ltd
                </li>
                <li>
                  <FaStar /> Embassy Tech Village
                </li>
                <li>
                  <FaStar /> Rmz EcoWorld
                </li>
              </ul>
            </div>
            <br />
            <div>
              <TitleComponent
                title="Top Educational Insititutions"
                size="1.5vw"
                color="#000"
                case="uppercase"
              />

              <ul>
                <li>
                  <FaStar /> Vishwa Vidhyapeet School 
                </li>
                <li>
                  <FaStar /> VedaVihaan The Global School
                </li>
                <li>
                  <FaStar /> Glentree Academy
                </li>
                <li>
                  <FaStar /> Whitefield Global School
                </li>
              </ul>
            </div>
          </div>
        </span>
      </Container>
    </>
  );
};

export default LocationComponet;

const first = css`
  display: grid;
  grid-template-columns: 7fr 4fr;
  justify-content: space-between;
  @media(max-width:600px){
    display:block;
  }
`;

const second = css`
  text-align: center;
  ul {
    list-style-type: none;
    text-align: left;
    padding-left: 14%;
    li {
      margin-bottom: 10px;
    }
  }
  @media(max-width:600px){
   display:none;
  }
`;
