import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const NavItem = styled(Link)`
  text-decoration: none;
  color: #111;
  display: inline-block;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 200ms ease-in;
  position: relative;
  flex: 1 1 auto;
  font-size: 1.25rem;
  font-weight: 490;
  letter-spacing: 0.025rem;
  margin-right: 10px;
  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: goldenrod;
    height: 1px;
    transition: all 0.4s ease-in;
  }

  :hover {
    color: goldenrod;
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    font-size: 1.5rem;
    z-index: 6;
    margin-bottom: 50px;
  }
`
const NavbarLinks = () => {
  return (
    <>
      <NavItem to="/" activeClassName="active">Home</NavItem>
      <NavItem to="/about" activeClassName="active">About</NavItem>
      <NavItem to="/price" activeClassName="active">Price</NavItem>
      <NavItem to="/siteandfloorplan" activeClassName="active">Site & Floor Plan</NavItem>
      <NavItem to="/amenities" activeClassName="active">Amenities</NavItem>
      <NavItem to="/gallery" activeClassName="active">Gallery</NavItem>
      <NavItem to="/location" activeClassName="active">Location</NavItem>
      <NavItem to="/vituralsitetour" activeClassName="active">Virtual Site Tour</NavItem>
    </>
  )
}

export default NavbarLinks